import React from 'react'
// import PropTypes from 'prop-types'
import {useI18next, useTranslation} from 'gatsby-plugin-react-i18next'
import imgPlaceholder from 'images/lawyer-test.png'
import {
  Inner,
  Outer,
  ServicesItems,
  ServicesItem,
  Top,
  Button,
  Name,
  PhotoOuter,
  PhotoInner,
  Photo,
  Filter,
  Bullet,
} from './LawyerCard.styles'

const LawyerCard = ({children, name, photo, services, pageUrl, ...rest}) => {
  const {t, language} = useI18next()
  return (
    <Outer {...rest}>
      <Inner>
        <PhotoOuter>
          <PhotoInner>
            <Filter />
            <Photo src={photo} />
          </PhotoInner>
        </PhotoOuter>
        <Top>
          <Name content={name} size="md" mb={17} fontWeight={700} uppercase />
          <ServicesItems>
            {services.map(item => (
              <ServicesItem key={item.name[language]}>
                <Bullet /> {item.name[language]}
              </ServicesItem>
            ))}
          </ServicesItems>
        </Top>
        <Button to={`/lawyers/${pageUrl}`}>{t('sections.lawyers.profileButton')}</Button>
      </Inner>
    </Outer>
  )
}

LawyerCard.propTypes = {}
LawyerCard.defaultProps = {}

export default LawyerCard
