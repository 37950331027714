import React from 'react'
// import PropTypes from 'prop-types'
import Slider from 'react-slick'
import testImg from 'images/lawyer-test.png'
import Awards from 'components/Awards'
import {useI18next, useTranslation} from 'gatsby-plugin-react-i18next'
import {Breadcrumb} from 'gatsby-plugin-breadcrumb'
import {
  Wrapper,
  Name,
  Photo,
  PhotoWrapper,
  Position,
  SliderWrapper,
  PhotoWrapperOuter,
  AwardsWrapperDesk,
  FilterOuter,
  FilterInner,
  BreadcrumbsWrapper,
  Container,
  Title,
  Title2,
  AwardsWrapperMob,
  ServiceSmallTitle,
  Title2Dirk,
} from './HeroWithSlider.styles'

const HeroWithSlider = ({
  data,
  dataSingleService,
  dataSingle,
  title,
  title_first,
  title_second,
  serviceSmallTitle,
  crumbs,
  crumbLabel,
  lawyer,
  ...rest
}) => {
  const {language, changeLanguage} = useI18next()
  const {t} = useTranslation()
  const settings = {
    dots: true,
    arrows: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 5000,
  }
  return (
    <Wrapper {...rest}>
      <FilterOuter />
      <Container>
        <SliderWrapper>
          <BreadcrumbsWrapper>
            <Breadcrumb
              crumbs={crumbs}
              crumbSeparator=" ▸ "
              crumbLabel={crumbLabel}
              hiddenCrumbs={language === 'de' ? ['/en'] : ['/']}
            />
          </BreadcrumbsWrapper>
          {lawyer && (
            <>
              {title_first && title_second && (
                <Title2
                  content={[title_first, <br />, title_second]}
                  size="title"
                  component="h1"
                  color="common.white"
                />
              )}
            </>
          )}
          {title && (
            <Title
              content={title}
              dirk={title === 'Dirk Stresska'}
              lawyer={lawyer}
              size="title"
              component="h1"
              color="common.white"
            />
          )}
          <Slider {...settings}>
            {data &&
              data.map(item => (
                <div key={item.node.name}>
                  <PhotoWrapperOuter>
                    <PhotoWrapper>
                      <Photo src={item.node.photo.url} />
                    </PhotoWrapper>
                    <Name color="common.white" fontWeight={700}>
                      {item.node.name}
                    </Name>
                    <Position color="common.white" size="xs">
                      {item.node.position[language]}
                    </Position>
                    <AwardsWrapperDesk>
                      <Awards />
                    </AwardsWrapperDesk>
                    <FilterInner />
                  </PhotoWrapperOuter>
                </div>
              ))}
            {dataSingleService &&
              dataSingleService.map(item => (
                <>
                  {item.lawyers.map(i => (
                    <div key={i.name}>
                      <PhotoWrapperOuter>
                        <PhotoWrapper>
                          <Photo src={i.photo.url} />
                        </PhotoWrapper>
                        <ServiceSmallTitle color="common.white">
                          {serviceSmallTitle}
                        </ServiceSmallTitle>
                        <Name color="common.white" fontWeight={700}>
                          {i.name}
                        </Name>
                        <Position color="common.white" size="xs">
                          {/* {item.position[language]} */}
                        </Position>
                        <AwardsWrapperDesk>
                          <Awards />
                        </AwardsWrapperDesk>
                        <FilterInner />
                      </PhotoWrapperOuter>
                    </div>
                  ))}
                </>
              ))}
            {dataSingle && (
              <div>
                <PhotoWrapperOuter>
                  <PhotoWrapper>
                    <Photo src={dataSingle.photo.url} />
                  </PhotoWrapper>
                  <Name color="common.white" fontWeight={700}>
                    {dataSingle.name}
                  </Name>
                  <Position color="common.white" size="xs">
                    {dataSingle.position[language]}
                  </Position>
                  <AwardsWrapperDesk>
                    <Awards />
                  </AwardsWrapperDesk>
                  <FilterInner />
                </PhotoWrapperOuter>
              </div>
            )}
          </Slider>
        </SliderWrapper>
        <AwardsWrapperMob>
          <Awards />
        </AwardsWrapperMob>
      </Container>
    </Wrapper>
  )
}

HeroWithSlider.propTypes = {}
HeroWithSlider.defaultProps = {}

export default HeroWithSlider
