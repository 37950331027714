import styled from 'styled-components'
import ContentContainer from 'components/ContentContainer'
import sliderDotInactive from '../../images/common/slider-dot-inactive.svg'
import sliderDotActive from '../../images/common/slider-dot-active.svg'
import Text from '../Text'

export const Wrapper = styled.div(({theme}) => ({
  position: 'relative',
  paddingTop: 20,
  background: theme.palette.grey[900],
  width: '100%',

  [theme.breakpoints.down('tablet')]: {
    // marginRight: -15,
    // marginLeft: -15,
    paddingTop: 0,
  },
}))

export const Container = styled(ContentContainer)(({theme}) => ({
  position: 'relative',
}))

export const FilterOuter = styled.div(({theme}) => ({
  position: 'absolute',
  width: '100%',
  height: 337,
  left: 0,
  bottom: 0,
  background: `linear-gradient(180deg, rgba(0, 0, 0, 0.0001) 0%, #000000 84.9%)`,
  zIndex: 0,
  pointerEvents: 'none',
}))

export const SliderWrapper = styled.div(({theme}) => ({
  position: 'relative',
  maxWidth: 760,
  width: '100%',

  '& .slick-dots': {
    paddingLeft: 0,
    bottom: 26,
    textAlign: 'left',

    [theme.breakpoints.down('tablet')]: {
      paddingLeft: 15,
    },
  },

  '& .slick-dots li ': {
    marginLeft: 0,
    marginRight: 20,
    width: 5,
    height: 5,
  },

  '& .slick-dots li button': {
    padding: 0,
    width: 5,
    height: 5,
    background: `url(${sliderDotInactive}) center no-repeat`,
    backgroundSize: 'cover',
  },

  '& .slick-dots li.slick-active button': {
    background: `url(${sliderDotActive}) center no-repeat`,
    backgroundSize: 'cover',
  },

  '& .slick-dots li.slick-active button:before': {
    content: 'none',
  },

  '& .slick-dots li button:before': {
    content: 'none',
  },

  [theme.breakpoints.down('tablet')]: {
    paddingTop: 120,
  },
}))

export const BreadcrumbsWrapper = styled.div(({theme}) => ({
  position: 'absolute',
  top: 125,
  left: 0,
  zIndex: 2,

  [theme.breakpoints.down('tablet')]: {
    top: 30,
    left: 15,
  },
}))

export const Title = styled(Text)(({theme, lawyer, dirk}) => ({
  position: 'absolute',
  maxWidth: 500,
  // eslint-disable-next-line no-nested-ternary
  top: dirk ? 260 : lawyer ? 210 : 165,
  left: 0,
  zIndex: 2,
  textTransform: 'uppercase',

  [theme.breakpoints.down('tablet')]: {
    // eslint-disable-next-line no-nested-ternary
    top: dirk ? 150 : lawyer ? 110 : 65,
    left: 15,
  },
  [theme.breakpoints.down('mobileM')]: {
    // eslint-disable-next-line no-nested-ternary
    top: dirk ? 137 : lawyer ? 110 : 95,
  },
}))

export const Title2 = styled(Text)(({theme}) => ({
  position: 'absolute',
  top: 165,
  left: 0,
  zIndex: 2,
  textTransform: 'uppercase',

  [theme.breakpoints.down('tablet')]: {
    top: 65,
    left: 15,
  },
  [theme.breakpoints.down('mobileM')]: {
    top: 85,
  },
}))

export const Title2Dirk = styled(Text)(({theme}) => ({
  maxWidth: 300,
  position: 'absolute',
  top: 165,
  left: 0,
  zIndex: 2,
  textTransform: 'uppercase',

  [theme.breakpoints.down('tablet')]: {
    top: 65,
    left: 15,
  },
  [theme.breakpoints.down('mobileM')]: {
    top: 85,
  },
}))

export const PhotoWrapperOuter = styled.div(({theme}) => ({
  position: 'relative',
  zIndex: 2,
}))

export const FilterInner = styled.div(({theme}) => ({
  position: 'absolute',
  width: '100%',
  height: 337,
  left: 0,
  bottom: 0,
  background: `linear-gradient(180deg, rgba(0, 0, 0, 0.0001) 0%, #000000 84.9%)`,
  zIndex: 1,
  pointerEvents: 'none',

  [theme.breakpoints.down('tablet')]: {
    height: 200,
  },
}))

export const PhotoWrapper = styled.div(({theme}) => ({
  paddingBottom: '70%',
  position: 'relative',
  maxWidth: 435,
  marginLeft: 'auto',

  [theme.breakpoints.down('tablet')]: {
    marginLeft: 0,
  },

  [theme.breakpoints.down('tabletS')]: {
    paddingBottom: '120%',
    marginLeft: 0,
  },
}))

export const ServiceSmallTitle = styled(Text)(({theme}) => ({
  position: 'absolute',
  bottom: 115,
  left: 0,
  zIndex: 2,

  [theme.breakpoints.down('tablet')]: {
    bottom: 105,
    left: 15,
  },
}))

export const Name = styled(Text)(({theme}) => ({
  position: 'absolute',
  bottom: 75,
  left: 0,
  fontSize: theme.fontSizes.titleXs,
  zIndex: 2,
  textTransform: 'uppercase',

  [theme.breakpoints.down('tablet')]: {
    fontSize: theme.fontSizes.def,
    bottom: 65,
    left: 15,
  },
}))

export const Position = styled(Text)(({theme}) => ({
  position: 'absolute',
  bottom: 53,
  left: 0,
  zIndex: 2,
  textTransform: 'uppercase',

  [theme.breakpoints.down('tablet')]: {
    bottom: 40,
    left: 15,
  },
}))

export const AwardsWrapperDesk = styled.div(({theme}) => ({
  maxWidth: 300,
  width: '100%',
  position: 'absolute',
  bottom: 0,
  right: 0,
  zIndex: 2,

  [theme.breakpoints.down('tablet')]: {
    display: 'none',
  },
}))

export const AwardsWrapperMob = styled.div(({theme}) => ({
  display: 'none',

  [theme.breakpoints.down('tablet')]: {
    display: 'block',
  },
}))

export const Photo = styled.img(({theme}) => ({
  position: 'absolute',
  top: 0,
  left: 0,
  width: '100%',
  height: '100%',
  objectFit: 'cover',
}))
