import React from 'react'
// import PropTypes from 'prop-types'
import Text from 'components/Text'
import ContentContainer from 'components/ContentContainer'
import {LawyersWrapper, TextWrapper, Wrapper} from './LawyersSection.styles'
import LawyerCard from '../LawyerCard'

const LawyersSection = ({children, data, title, subtitle, ...rest}) => (
  <Wrapper {...rest}>
    <ContentContainer>
      <TextWrapper>
        <Text content={title} size="titleXs" mb={30} component="h2" uppercase />
        <Text content={subtitle} mb={0} />
      </TextWrapper>
      <LawyersWrapper>
        {data.map(item => (
          <LawyerCard
            key={item.node.name}
            name={item.node.name}
            photo={item.node.card_photo.url}
            services={item.node.services}
            pageUrl={item.node.page_url}
          />
        ))}
      </LawyersWrapper>
    </ContentContainer>
  </Wrapper>
)

LawyersSection.propTypes = {}
LawyersSection.defaultProps = {}

export default LawyersSection
