import styled from 'styled-components'

export const Wrapper = styled.div(({theme}) => ({}))

export const TextWrapper = styled.div(({theme}) => ({
  padding: '80px 0',
  maxWidth: 670,
  width: '100%',

  [theme.breakpoints.down('tablet')]: {
    padding: '60px 0',
  },
}))

export const LawyersWrapper = styled.div(({theme}) => ({
  maxWidth: 770,
  width: '100%',
  display: 'flex',
  flexWrap: 'wrap',
  justifyContent: 'space-between',
}))
