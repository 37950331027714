import React, {useEffect} from 'react'
import SEO from 'components/SEO'
import {useI18next, useTranslation} from 'gatsby-plugin-react-i18next'
import {graphql} from 'gatsby'
import ReviewsSection from 'components/ReviewsSection'
import BlogBlockSection from 'sections/BlogBlockSection'
import LawyersSection from '../components/LawyersSection'
import HeroWithSlider from '../components/HeroWithSlider'
import ShowMob from '../components/ShowMob'
import FormSubscribe from '../components/FormSubscribe'
import GeneralContacts from '../components/GeneralContacts'
import Header from "../components/Header";
import Layout from "../components/Layout";

export default function LawyersPage({
  pageContext: {
    breadcrumb: {crumbs},
  },
  data,
  location,
}) {
  const {language, changeLanguage} = useI18next()
  const {t} = useTranslation()

  useEffect(() => {
    if (typeof window !== 'undefined' && data.strapiLawyersPage.en_disabled) {
      changeLanguage('de')
    }
  }, [])

  return (
    <Layout dataLocales={data} location={location}>
    <Header dataLang={data.strapiLawyersPage.en_disabled} />
      <SEO
        title={data.strapiLawyersPage.seo_title[language]}
        description={data.strapiLawyersPage.seo_description[language]}
      />
      <HeroWithSlider
        data={data.allStrapiLawyer.edges}
        title={data.strapiLawyersPage.h1_title[language]}
        crumbs={crumbs}
      />
      <LawyersSection
        data={data.allStrapiLawyer.edges}
        title={t('sections.lawyers.title')}
        subtitle={t('sections.lawyers.subtitle')}
      />
      <ShowMob>
        <GeneralContacts />
      </ShowMob>
      <ReviewsSection />
      <BlogBlockSection
        data={data.allStrapiBlog.edges}
        title={t('sections.blog.title')}
        subtitle={t('sections.blog.subtitle')}
        location={location}
      />
    </Layout>
  )
}

export const pageQuery = graphql`
  query LawyersQuery ($language: String!) {
  locales: allLocale(filter: {language: {eq: $language}}) {
        edges {
          node {
            ns
            data
            language
          }
        }
      }
    allStrapiLawyer(sort: {order: ASC, fields: lawyer_order}) {
      edges {
        node {
          name
          page_url
          photo {
            url
          }
          card_photo {
            url
          }
          position {
            de
            en
          }
          services {
            name {
              de
              en
            }
          }
        }
      }
    }
    allStrapiBlog(sort: {order: DESC, fields: date_for_sort}) {
      edges {
        node {
          article {
            de
            en
          }
          date {
            de
            en
          }
          description_short {
            de
            en
          }
          navigation {
            de
            en
          }
          reading_time {
            de
            en
          }
          page_url
          date_for_sort
          published_at
          title {
            de
            en
          }
        }
      }
    }
    strapiLawyersPage {
      en_disabled
      seo_description {
        de
        en
      }
      seo_title {
        de
        en
      }
      h1_title {
        de
        en
      }
    }
  }
`
