import styled from 'styled-components'
import {Link} from 'gatsby'
import Text from 'components/Text'
import bullet from 'images/common/lawyer-card-bullet.inline.svg'

export const Outer = styled.div(({theme}) => ({
  // marginRight: 30,
  marginBottom: 30,
  maxWidth: 235,
  width: '100%',

  // '&:nth-child(3n)': {
  //   marginRight: 0,
  // },

  [theme.breakpoints.down('mobileL')]: {
    marginRight: 0,
    maxWidth: '100%',
  },
}))

export const Inner = styled.div(({theme}) => ({
  paddingTop: 204,
  paddingBottom: 33,
  position: 'relative',
  minHeight: 555,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  background: '#020202',
  color: theme.palette.common.white,
  zIndex: 2,

  [theme.breakpoints.down('mobileL')]: {
    minHeight: 500,
  },
}))

export const Top = styled.div(({theme}) => ({
  marginBottom: 15,
}))

export const PhotoOuter = styled.div(({theme}) => ({
  position: 'absolute',
  top: 0,
  left: 0,
  width: '100%',
  zIndex: -1,

  [theme.breakpoints.down('mobileL')]: {
    maxWidth: 235,
    left: '50%',
    transform: 'translateX(-50%)',
  },
}))

export const PhotoInner = styled.div(({theme}) => ({
  paddingBottom: '100%',
  position: 'relative',
}))

export const Filter = styled.div(({theme}) => ({
  position: 'absolute',
  width: '100%',
  height: 100,
  bottom: -20,
  left: 0,
  background: 'linear-gradient(180deg, rgba(0, 0, 0, 0.0001) 0%, #000000 84.9%)',
  zIndex: 1,
}))

export const Photo = styled.img(({theme}) => ({
  position: 'absolute',
  top: 0,
  left: 0,
  width: '100%',
  height: '100%',
  objectFit: 'cover',
}))

export const Name = styled(Text)(({theme}) => ({
  padding: '0 15px',

  [theme.breakpoints.down('mobileL')]: {
    padding: '0 40px',
  },
}))

export const ServicesItems = styled.div(({theme}) => ({
  padding: '0 15px',
  display: 'flex',
  flexDirection: 'column',

  [theme.breakpoints.down('mobileL')]: {
    padding: '0 30px',
  },
}))

export const ServicesItem = styled.div(({theme}) => ({
  display: 'flex',
  alignItems: 'flex-start',
}))

export const Bullet = styled(bullet)(({theme}) => ({
  marginRight: 15,
  marginTop: 8,
}))

export const Button = styled(Link)(({theme}) => ({
  margin: '0 auto',
  maxWidth: 155,
  width: '100%',
  height: 40,
  border: `1px solid ${theme.palette.common.white}`,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',

  [theme.breakpoints.down('mobileL')]: {
    maxWidth: 215,
  },
}))
